import React from 'react';
import styled, { css } from 'styled-components';
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image';
import mq, { mqdown, breakpoints } from '@mq';

// return to this eventually
// import Caption from '@core/Caption';
// {caption && <Caption text={image.description || caption} />}

const Image = ({ image, objectFit, imgStyle, loading, ...rest }) => (
  <figure {...rest}>
    <GatsbyImage
      image={getImage(image.gatsbyImageData)}
      alt={image.description || image.altText || ''}
      objectFit={objectFit}
      imgStyle={imgStyle}
      loading={loading}
    />
  </figure>
);

export default Image;
