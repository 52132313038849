import React from 'react';
import { graphql } from 'gatsby';
import styled, { css } from 'styled-components';

import mq, { mqdown } from '@mq';
import { center, grid } from '@mixins';
import CoreImage from '@core/Image';
import Link from '@core/Link';
import Seo from '@core/Seo';

const PublicationsPage = ({
  data: {
    contentfulPublicationsPage: { publications },
  },
}) => (
  <>
    <Seo pageTitle="Archive" />
    <Main>
      <PublicationsList>
        {publications.map((publication, index) => (
          <li key={index}>
            <Publication {...publication} />
          </li>
        ))}
      </PublicationsList>
    </Main>
  </>
);

const Publication = ({ image, title, author, publisher, year, details, link }) => (
  <PublicationContainer to={link}>
    <Image image={image} objectFit="contain" />
    <Text>
      <Title>{title}</Title>
      {author && <Title>{author}</Title>}
      <Info>
        {publisher && <Publisher>{publisher}</Publisher>}
        {year && <Year>{`, ${year}`}</Year>}
      </Info>
      {details && <Details>{details}</Details>}
    </Text>
  </PublicationContainer>
);

const Main = styled.main`
  margin: 7.75rem 0;

  ${mq.medium} {
    margin: 10.0625rem 0;
  }
`;

const PublicationsList = styled.ul`
  ${grid};
  row-gap: 5rem;

  li {
    grid-column-end: span 6;

    ${mq.small} {
      grid-column-end: span 2;
    }

    ${mq.medium} {
      grid-column-end: span 4;
    }
  }
`;

const Publisher = styled.div``;

const Title = styled.div`
  font-style: normal !important;
  font-feature-settings: normal !important;
`;

const Details = styled.div`
  font-style: normal !important;
  font-feature-settings: normal !important;
`;

const Year = styled.div`
  position: relative;
  font-style: normal !important;
  font-feature-settings: normal !important;
  ${center};
`;

const PublicationContainer = styled(Link)`
  display: grid;
  row-gap: 0.75rem;

  ${props =>
    props.to &&
    css`
      ${Publisher} {
        text-decoration: underline;
      }
    `}
`;

const Image = styled(CoreImage)`
  ${mqdown.medium} {
    [data-gatsby-image-wrapper] {
      height: 0;
      padding-bottom: 100%;
      position: relative;
    }
  }

  ${mq.medium} {
    height: 28rem;

    [data-gatsby-image-wrapper] {
      height: 100%;
      width: 100%;
    }
  }
`;

const Text = styled.div`
  text-align: center;
`;

const Info = styled.div`
  display: flex;
  justify-content: center;
`;

export const publicationsPageQuery = graphql`
  query PublicationsPageQuery {
    contentfulPublicationsPage {
      publications {
        title
        author
        publisher
        year
        details
        link
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            breakpoints: [375, 560, 750, 1080, 1366, 1920, 2560]
            placeholder: NONE
            formats: [AUTO, WEBP, AVIF]
            quality: 75
          )
        }
      }
    }
  }
`;

export default PublicationsPage;
